import { X } from '@phosphor-icons/react'
import * as React from 'react'
import { ToastPosition, toast } from 'react-hot-toast'

export const successToast = (
  notice: React.ReactNode,
  position?: ToastPosition
) =>
  toast(
    (t) => (
      <span className="flex justify-between">
        {notice}
        <button
          aria-label="Close"
          className="close"
          onClick={() => toast.dismiss(t.id)}
        >
          <X className="h-4 text-white w-4" weight="bold" />
        </button>
      </span>
    ),
    {
      className: 'bg-green-600 text-white text-center',
      position: position || 'top-center',
    }
  )
